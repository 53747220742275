////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-secondaryColor {
  background-color: rgba(179, 175, 158, 1);
}
.bg-thirdColor {
  background-color: rgba(179, 175, 158, 1);
}
.bg-fourthColor {
  background-color: rgba(210, 208, 198, 1);
}
.bg-fifthColor {
  background-color: rgba(237, 236, 232, 1);
}
.bg-sixthColor {
  background-color: rgba(210, 208, 198, 1);
}
.bg-seventhColor {
  background-color: rgba(237, 236, 232, 1);
}
